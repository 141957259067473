import * as React from "react";
import { Link } from "gatsby";
import Layout from "../components/layout/layout";

// markup
const NotFoundPage = () => {
  return <Layout>Not found</Layout>;
};

export default NotFoundPage;
